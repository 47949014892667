import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"

const Accessibility = ({ pageContext, location }) => {
  return (
    <Layout>
      <InnerHeader
        pageTitle="accessibility"
        pageContext={pageContext}
        location={location}
      />
      <div class="inner-container inner-y-padding">
        <h2>
          <strong>Website and Technology Accessibility Statement:</strong>
        </h2>
        <ul>
          <li>
            <p>
              <strong>Accessibility Assistance.</strong> If you have difficulty
              using or accessing any element of this website or on any Rocky
              Brands mobile app or tool, please feel free to call us at
              <a href="tel:1-866-442-4908" title="Call Rocky Brands">
                1-866-442-4908
              </a>{" "}
              or email us at{" "}
              <a
                href="mailto: accessibility@rockybrands.com"
                title="Email Rocky Brands regarding accessibility issues"
              >
                accessibility@rockybrands.com
              </a>{" "}
              and we will work with you to provide the information, item, or
              transaction you seek through a communication method that is
              accessible for you consistent with applicable law (for example,
              through telephone support).
            </p>
          </li>
          <li>
            <p>
              <strong>Goal of being better for All.</strong> Rocky Brands
              desires to provide a positive customer experience to all our
              customers, and we aim to promote accessibility and inclusion. Our
              goal is to permit our customers to successfully gather information
              and transact business through our website.
            </p>
          </li>
          <li>
            <p>
              Whether you are using assistive technologies like a screen reader,
              a magnifier, voice recognition software, or captions for videos,
              our goal is to make your use of Rocky Brands’s website a
              successful and enjoyable experience.
            </p>
          </li>
          <li>
            <p>
              <strong>Actions.</strong> We are taking a variety of steps and
              devoting resources to further enhance the accessibility of our
              website.
            </p>
          </li>
          <li>
            <p>
              <strong>Ongoing Effort.</strong> Although we are proud of the
              efforts that we have completed and that are in-progress, we at
              Rocky Brands view accessibility as an ongoing effort.
            </p>
          </li>
          <li>
            <p>
              <strong>Feedback.</strong> Please contact us if you have any
              feedback or suggestions as to how we could improve the
              accessibility of this website.
            </p>
          </li>
        </ul>

        {/* description for search, hidden from page */}
        <p
          data-typesense-field="description"
          style={{ display: `none` }}
          aria-hidden="true"
        >
          Rocky Brands is committed to providing a website that is accessible to
          the widest possible audience, regardless of technology or ability. We
          are actively working to increase the accessibility and usability of
          our website and in doing so adhere to many of the available standards
          and guidelines.
        </p>
      </div>
    </Layout>
  )
}

export default Accessibility

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Accessibility Statement</title>
    <meta
      name="description"
      content="Rocky Brands is committed to providing a website that is accessible to the widest possible audience, regardless of technology or ability."
    />
  </>
)
